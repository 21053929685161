<template>
    <div class="content-box">
        <el-card class="my-20">
            <div class="d-flex-center">
                <div class="d-inline-flex-center is-link" @click="$nav.back()">
                    <i class="icon icon-back my-8 mr-8" />
                    <span class="color-666">{{ $t('base.back') }}</span>
                </div>
                <div class="font-16 ml-20 pl-20 mr-auto font-bold color-666 border-left">{{ $t('subscribe.project_details') }}</div>
                <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchData()">{{ $t('base.refresh') }}</el-button>
            </div>
        </el-card>
        <el-card class="mb-30">
            <el-row :gutter="20">
                <el-col :span="5">
                    <div>
                        <img class="w-100 border-r-8 coin-logo" :src="info.logo" alt="Logo">
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="mr-10">
                        <div class="d-flex-center mb-10">
                            <h1 class="mr-auto my-0">{{ info.coin }}</h1>
                            <span>{{ $t('subscribe.end_date') }}</span>
                            <span class="mx-5">:</span>
                            <span class="" v-text="info.end_date" />
                        </div>
                        <div class="price-box d-flex-center p-20 font-bold border-r mb-20">
                            <span class="label mr-5">{{ $t('subscribe.subscription_ratio') }} : </span>
                            <span class="font-18 mr-auto">{{ info.price }} USDT</span>
                            <span class="font-20 color-p">≈ 1 {{ info.coin }}</span>
                        </div>
                        <el-row class="text-center mb-30">
                            <el-col :span="6">
                                <div class="font-12">{{ $t('subscribe.issue_quota') }}</div>
                                <div class="font-bold mt-5">{{ info.num }}</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="font-12">{{ $t('subscribe.participating') }}</div>
                                <div class="font-bold mt-5">{{ info.sales }}</div>
                            </el-col>
                            <el-col :span="6">
                                <div class="font-12">{{ $t('subscribe.lock_period') }}</div>
                                <div class="font-bold mt-5">
                                    <spam>{{ info.cycle }}</spam>
                                    <span class="ml-5 font-14">{{ $t('subscribe.months') }}</span>
                                </div>
                            </el-col>
                            <el-col :span="6">
                                <div class="font-12">{{ $t('subscribe.purchase_limit') }}</div>
                                <div class="font-bold mt-5">{{ info.buy_limit }} ~ {{ info.buy_top }}</div>
                            </el-col>
                            <!-- <el-col :span="5">
                                <span class="font-12">{{ $t('subscribe.thaw_interval') }}</span>
                                <div class="font-bold mt-5">{{ info.thaw }} {{ $t('subscribe.hours') }}</div>
                            </el-col> -->
                        </el-row>
                        <el-progress :text-inside="true" :stroke-width="26" :percentage="info.progress"></el-progress>
                    </div>
                </el-col>
                <el-col :span="7">
                    <el-form
                            v-loading="formLoading"
                            ref="ruleForm"
                            class="white-form"
                            :model="formData"
                            :rules="rules"
                        >
                            <div class="font-16 d-flex-center mb-10 mt-3">
                                <span class="mr-auto">{{ $t('subscribe.available') }} USDT :</span>
                                <span class="font-bold" v-text="buyInfo.usdt" />
                            </div>
                            <el-form-item prop="amount">
                                <el-input v-model="formData.amount" prefix-icon="el-icon-coin" :placeholder="$t('amount')">
                                    <el-button slot="append" type="warning" plain @click="setMax()">{{ $t('MAX') }}</el-button>
                                </el-input>
                            </el-form-item>
                            <el-form-item prop="pay_password">
                                <el-input v-model="formData.pay_password" prefix-icon="el-icon-lock" show-password :placeholder="$t('user.trade_pwd')" />
                            </el-form-item>
                            <div class="mb-15 font-14 d-flex-center">
                                <div class="mr-auto">{{ $t('subscribe.total') }} USDT : <span class="font-bold" v-text="totalUsdt" /></div>
                                <div>{{ $t('subscribe.fee') }} : 0%</div>
                            </div>
                            <el-form-item>
                                <el-button v-if="$store.state.userToken" type="success" icon="el-icon-s-promotion" class="w-100" native-type="button" @click="submitForm()">{{ $t('subscribe.get_involved_now') }}</el-button>
                                <el-button v-else type="danger" icon="el-icon-user" class="w-100" native-type="button" @click="$nav.push('/user/login')">{{ $t('base.login_first') }}</el-button>
                            </el-form-item>
                        </el-form>
                </el-col>
            </el-row>
        </el-card>
        <el-card class="mb-20 des-box">
            <div slot="header" class="clearfix">
                <div class="d-flex-center">
                    <h4 class="mr-auto my-0">{{ $t('subscribe.project_des') }}</h4>
                    <a :href="info.white_paper" target="_blank">
                        <el-button icon="el-icon-document" type="primary" plain>{{ $t('subscribe.white_paper') }}</el-button>
                    </a>
                </div>
            </div>
            <div v-html="info.des" />
        </el-card>
        <el-card class="mb-30 color-black color-d text-center warning-box">
            <i class="el-icon-warning-outline" />
            Digital assets are innovative investment products, which may have problems such as pre mining of miners, manoeuvring of makers, team dissolution, technical defects, etc.; their prices fluctuate greatly, which has high investment risk. Please fully understand the digital assets before investment, judge your investment ability rationally, and make investment decisions prudently.
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            info: {
                coin: '',
                price: '0',
                des: ''
            },
            buyInfo: {
                usdt: '0.00000000'
            },
            timer: null,
            formLoading: false,
            formData: {
                coin: this.$route.params.coin,
                amount: '',
                pay_password: ''
            },
            rules: {
                amount: [
                    { required: true, trigger: 'blur', message: this.$t('wallet.pea') }
                ],
                pay_password: [
                    { required: true, type: 'string', min: 6, trigger: 'blur', message: this.$t('wallet.petp') }
                ]
            }
        }
    },
    computed: {
        totalUsdt(){
            if(!this.formData.amount){
                return '--'
            }
            return (this.info.price * this.formData.amount).toFixed(8) * 1
        }
    },
    created: function () {
        this.fatchData()
        this.fatchBuyInfo()
        this.timer = window.setInterval(() => {
            if (document.hidden) return
            this.fatchPrice()
        }, 2000)
    },
    beforeDestroy: function () {
        window.clearInterval(this.timer)
    },
    methods: {
        fatchData: async function () {
            this.loading = true
            const { data } = await this.$request.get('coin/item/coin/' + this.$route.params.coin)
            this.info = data
            this.loading = false
        },
        setMax: function(){
            if(this.buyInfo.usdt <= 0){
                this.formData.amount = ''
                return
            }
            const max = Math.floor(this.buyInfo.usdt / this.info.price * 0.999)
            if(max >= this.info.buy_top){
                this.formData.amount = this.info.buy_top * 1
                return
            }
            this.formData.amount = max
        },
        fatchBuyInfo: async function () {
            if(!this.$store.state.userToken) return
            this.loading = true
            const { data } = await this.$request.get('coin/info/coin/' + this.$route.params.coin)
            this.buyInfo = data
            this.loading = false
        },
        fatchPrice: async function () {
            const { data } = await this.$request.get('coin/price/coin/'+this.$route.params.coin)
            this.info.price = data
        },
        submitForm: async function (){
            try {
                await this.$refs.ruleForm.validate()
                this.formLoading = true
                await this.$request.post('coin/submit', this.formData)
                this.formData.amount = ''
                this.formData.pay_password = ''
            } catch (error) {
                //
            }
            this.formLoading = false
        }
    }
}
</script>
<style>
.white-form input{
    color: black !important;
}
</style>
<style lang="scss" scoped>
.coin-logo{
    object-fit: fill;
    height: 220px;
}
.price-box{
    background-color: #f1f1f3;
}
.des-box{
    line-height: 22px;
}
.warning-box{
    line-height: 24px;
}
</style>